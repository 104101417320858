// 创建一个名为 request.js 的文件
import axios from 'axios';

// 创建 Axios 实例，可以在此处配置基础 URL、超时时间等
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 50000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器，可以添加 token 或其他公共请求头
service.interceptors.request.use(
  (config) => {
    // 若存在全局 token，则附带在请求头中
    const token = localStorage.getItem('token'); // 假设已存储在本地
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器，处理异常、统一处理响应数据格式等
service.interceptors.response.use(
  (response) => {
    const { status, data } = response;

    // 判断 HTTP 状态码是否正常
    if (status >= 200 && status < 300) {
      return data;
    } else {
      // 处理非2xx响应
      return Promise.reject(new Error('请求失败'));
    }
  },
  (error) => {
    if (error.response) {
      // 请求已发出但服务器响应的状态码不在2xx范围
      console.log(`请求错误：${error.response.status}`);
    } else if (error.request) {
      // 请求已发出，但无响应
      console.log('请求未能发出');
    } else {
      // 发生了错误，导致请求无法发出
      console.log('发生了无法预见的错误', error.message);
    }

    // 返回错误给调用者
    return Promise.reject(error);
  }
);

export default service;
