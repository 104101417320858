<template>
  <div class="school">
    <SchoolPdf :schoolInfo="schoolInfo" />
  </div>
</template>

<script>
import SchoolPdf from "@/components/pdfTempaltes/School.vue";

export default {
  name: "School",
  components: { SchoolPdf },
  data() {
    return {
      // schoolName、result、resultWithPercent
      schoolInfo: window.schoolInfo || {},
    };
  },
  computed: {},
  beforeCreate() {
    return;
    // 本地模拟数据
    window.schoolInfo = {
      schoolName: "明昇第二幼儿园",
      result: {
        "0-20": 32,
        "21-40": 45,
        "41-60": 54,
        "61-80": 59,
        "81-100": 135,
      },
      resultWithPercent: {
        "0-20": "0.10",
        "21-40": "0.14",
        "41-60": "0.17",
        "61-80": "0.18",
        "81-100": "0.42",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.school {
}
</style>