<template>
  <div class="wrapper">
    <div class="header">
      <div class="img img1">
        <img src="../../assets/header1.png" alt="" />
      </div>
      <div class="img img2">
        <img src="../../assets/header2.png" alt="" />
      </div>
      <div class="img img3">
        <img src="../../assets/header3.png" alt="" />
      </div>
    </div>
    <div class="content">
      <p class="title">
        尊敬的
        <span class="name underline">{{ sourceInfo.name }}</span>
        家长：
      </p>
      <p class="indent">
        感谢您参与香港理工大学中文及双语系盛欐教授与中南大学湘雅二医院康复医学科王如蜜主任开展合作的汉语儿童语言发展能力项目，并于2024年1月填写了儿童沟通能力核检表（第二版）问卷。基于您提交的信息，
        <span class="name underline">{{ sourceInfo.name }}</span>
        整体沟通能力在同龄人中的表现如下（百分位数：参与测试的
        {{ gccInfo[2] }}
        %儿童低于贵子女）：
      </p>
      <div class="table">
        <table>
          <!-- 表头部分 -->
          <thead>
            <tr>
              <th>领域</th>
              <th>百分位数</th>
            </tr>
          </thead>

          <!-- 表体部分 -->
          <tbody>
            <tr>
              <td>言语</td>
              <td>{{ tableScorePrecent.A }}</td>
            </tr>
            <tr>
              <td>语法</td>
              <td>{{ tableScorePrecent.B }}</td>
            </tr>
            <tr>
              <td>语义</td>
              <td>{{ tableScorePrecent.C }}</td>
            </tr>
            <tr>
              <td>连贯性</td>
              <td>{{ tableScorePrecent.D }}</td>
            </tr>
            <tr>
              <td>开启话题</td>
              <td>{{ tableScorePrecent.E }}</td>
            </tr>
            <tr>
              <td>刻板语言</td>
              <td>{{ tableScorePrecent.F }}</td>
            </tr>
            <tr>
              <td>情境</td>
              <td>{{ tableScorePrecent.G }}</td>
            </tr>
            <tr>
              <td>非口语沟通</td>
              <td>{{ tableScorePrecent.H }}</td>
            </tr>
            <tr>
              <td>社会关系</td>
              <td>{{ tableScorePrecent.I }}</td>
            </tr>
            <tr>
              <td>兴趣</td>
              <td>{{ tableScorePrecent.J }}</td>
            </tr>
            <tr class="total">
              <td>整体沟通能力综合</td>
              <td>{{ gccInfo[2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="indent">
        请注意，以上分数基于您填写的问卷内容得出（若您曾多次提交问卷则以最新一次提交内容为准）。测试结果根据所提供信息的准确性可能存在一定误差，敬请理解。若您对以上结果有任何疑问，欢迎与我们联系（香港理工大学：
        <a href="mailto:hkpullblab@gmail.com">hkpullblab@gmail.com</a>
        ；湘雅二医院：
        <a href="mailto:tanyuallen@163.com">tanyuallen@163.com</a>
        ）。
      </p>

      <p class="more indent">
        此外，我们很高兴地通知您，我们将在年后继续开展下一阶段的项目：我们将首先在校内对本年级所有儿童进行语言能力的快速筛查，并随后邀请二百余名儿童免费参与价值上千元的语言能力、智商、工作记忆能力及注意力的综合评估。参与评估的儿童家长将会得到一份有关子女在以上领域评估结果的综合报告，部分语言能力薄弱的儿童也将有机会免费参与针对性的语言能力干预项目。
        <span class="warning">
          请注意，以上活动将均在您自愿的前提下进行，如若您对参与下一阶段的项目不感兴趣，可在2月15日前通过扫描下方二维码
          <a href="https://www.wjx.cn/vm/PprZeca.aspx#">（或点击此处进入问卷）</a>
          告知我们。
        </span>
        <span class="img">
          <img src="../../assets/bigclass.png" alt="" />
        </span>
      </p>

      <p class="indent">再次感谢您对我们工作的支持！</p>
    </div>
    <div class="footer">
      <p>香港理工大学【汉语儿童语言能力研究】课题组</p>
      <p>中南大学湘雅二医院康复医学科</p>
      <p>2024年2月</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigClass",
  props: ["studentInfo"],
  computed: {
    sourceInfo() {
      return this.studentInfo?.sourceInfo || {};
    },
    tableScorePrecent() {
      return this.studentInfo?.tableScore?.table_score_letter_precent || {};
    },
    gccInfo() {
      return this.studentInfo?.gccInfo || [];
    },
  },
};
</script>

<style scoped lang="less">
.underline {
  position: relative;
  display: inline-block;
  text-indent: 0;
}
.underline:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; /* 调整下划线与文本底部的距离 */
  border-bottom: 1px solid black; /* 下划线样式 */
}
.indent {
  text-indent: 4ch;
}
.wrapper {
  line-height: 2;
  background: white;
  min-width: 600px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .img {
      &.img1 {
        width: 200px;
      }
      &.img2 {
        width: 100px;
      }
      &.img3 {
        width: 200px;
      }
      img {
        width: 100%;
      }
    }
  }
  .content {
    a {
      text-decoration: underline;
    }
    .name {
      padding: 0 6px;
    }
    .title {
      .name {
        font-weight: bolder;
      }
    }

    .table {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0;

      table {
        width: 50%;
        border-collapse: collapse;
        border: 1px solid #ccc;
        text-align: center;
        thead {
          background: #a12337;
          color: white;
        }
        th {
          border: 1px solid black;
        }
        td {
          border: 1px solid black;
        }
        tr.total {
          background: #a12337;
          color: white;
        }
      }
    }
    .more {
      .warning {
        color: red;
        a {
          color: red;
          padding: 8px;
        }
      }
      .img {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 200px;
        }
      }
    }
  }
  .footer {
    text-align: right;
  }
}
</style>