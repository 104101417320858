<template>
  <div class="login">
    <h2>报告查询</h2>
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.name"
        name="name"
        label="孩子姓名"
        placeholder="孩子姓名"
        clearable
        :rules="[{ required: true, message: '孩子姓名为必填项' }]"
      />
      <van-field
        readonly
        clickable
        name="birth"
        :value="form.birth"
        label="孩子生日"
        placeholder="点击选择生日"
        :rules="[{ required: true, message: '孩子生日为必填项' }]"
        @click="onDateFieldClick"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="dateValue"
          type="date"
          @confirm="onConfirmDate"
          @cancel="showPicker = false"
          :minDate="minDate"
          :maxDate="maxDate"
        />
      </van-popup>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          立即查看
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
const defaultDate = new Date(2015, 0, 1);
export default {
  name: "Login",
  data() {
    return {
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      showPicker: false,
      dateValue: defaultDate,
      form: {
        name: "",
        birth: "",
      },
    };
  },
  methods: {
    onDateFieldClick() {
      this.showPicker = true;
      this.dateValue = this.form.birth
        ? new Date(this.form.birth)
        : defaultDate;
    },
    onConfirmDate(date) {
      this.form.birth = dayjs(date).format("YYYY-MM-DD");
      this.showPicker = false;
    },
    onSubmit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  padding: 16px;
  margin: 100px auto;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>