import axios from '@/utils/axios';

export const getStudentInfoByName = async (name, birth) => {
  const studentInfo = await axios.post(`/students/info`, {
    name,
    birth
  });

  return studentInfo;
};
export const getSchools = async () => {
  const schoolInfo = await axios.get('students/school');
  return schoolInfo;
};

export const checkFile = async (url) => {
  const res = await axios.post(`/students/checkFile`, {
    url
  });

  return res?.exists;
};

export default {
  getStudentInfoByName,
  getSchools,
  checkFile
};