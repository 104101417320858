import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Student from "../views/Student.vue";
import School from "../views/School.vue";
import Pdf from "../views/Pdf.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/AboutView.vue")
  },
  {
    path: "/student",
    name: "student",
    component: Student,
    meta: {
      title: '学生信息'
    }
  },
  {
    path: "/school",
    name: "school",
    component: School,
    meta: {
      title: '报告查看'
    }
  },
  {
    path: "/pdf",
    name: "pdf",
    component: Pdf,
    meta: {
      title: '报告查看'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  document.title = to.meta.title || 'GCC'; // 页面标题默认值
});

export default router;