<template>
  <div class="wrapper">
    <div class="header">
      <div class="img img1">
        <img src="../../assets/header1.png" alt="" />
      </div>
      <div class="img img2">
        <img src="../../assets/header2.png" alt="" />
      </div>
      <div class="img img3">
        <img src="../../assets/header3.png" alt="" />
      </div>
    </div>
    <div class="content">
      <p class="title">
        尊敬的
        <span class="name underline">{{ schoolName }}</span>
        领导：
      </p>
      <p class="indent">
        感谢贵校参与香港理工大学中文及双语系盛欐教授与中南大学湘雅二医院康复医学科王如蜜主任开展合作的汉语儿童语言发展能力项目，并于2024年1月组织贵校儿童家长填写了儿童沟通能力核检表（第二版）问卷。基于贵校家长提交的信息，本校儿童得分分布如下：
      </p>
      <div class="table">
        <table>
          <!-- 表头部分 -->
          <thead>
            <tr>
              <th>整体沟通能力得分百分位数</th>
              <th>人数</th>
              <th>比例</th>
            </tr>
          </thead>

          <!-- 表体部分 -->
          <tbody>
            <tr v-for="item in ranges">
              <td>{{ item }}</td>
              <td>{{ counts[item] }}</td>
              <td>{{ percents[item] | percent }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="indent">
        请注意，以上分数基于儿童家长填写的问卷内容得出，测试结果根据所提供信息的准确性可能存在一定误差，敬请理解。若贵校对以上结果有任何疑问，欢迎与我们联系（香港理工大学：
        <a href="mailto:hkpullblab@gmail.com">hkpullblab@gmail.com</a>
        ；湘雅二医院：
        <a href="mailto:tanyuallen@163.com">tanyuallen@163.com</a>
        ）。
      </p>

      <p class="indent">再次感谢您对我们工作的支持！</p>
    </div>
    <div class="footer">
      <p>香港理工大学【汉语儿童语言能力研究】课题组</p>
      <p>中南大学湘雅二医院康复医学科</p>
      <p>2024年2月</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "School",
  props: ["schoolInfo"],
  data() {
    return {
      ranges: ["0-20", "21-40", "41-60", "61-80", "81-100"],
    };
  },
  computed: {
    schoolName() {
      return this.schoolInfo.schoolName || "";
    },
    counts() {
      return this.schoolInfo.result || {};
    },
    percents() {
      return this.schoolInfo.resultWithPercent || {};
    },
  },
  filters: {
    percent(value) {
      return `${value}%`;
    },
  },
};
</script>

<style scoped lang="less">
.underline {
  position: relative;
  display: inline-block;
  text-indent: 0;
}
.underline:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; /* 调整下划线与文本底部的距离 */
  border-bottom: 1px solid black; /* 下划线样式 */
}
.indent {
  text-indent: 4ch;
}
.wrapper {
  line-height: 2;
  background: white;
  min-width: 600px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .img {
      &.img1 {
        width: 200px;
      }
      &.img2 {
        width: 100px;
      }
      &.img3 {
        width: 200px;
      }
      img {
        width: 100%;
      }
    }
  }
  .content {
    a {
      text-decoration: underline;
    }
    .name {
      padding: 0 6px;
    }
    .title {
      .name {
        font-weight: bolder;
      }
    }

    .table {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0;

      table {
        width: 50%;
        border-collapse: collapse;
        border: 1px solid #ccc;
        text-align: center;
        thead {
          background: #a12337;
          color: white;
        }
        th {
          border: 1px solid black;
        }
        td {
          border: 1px solid black;
        }
      }
    }
  }
  .footer {
    text-align: right;
  }
}
</style>