import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
  Form,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Notify,
  Toast,
  Icon,
  Sticky
} from 'vant';


Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Sticky);


Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");