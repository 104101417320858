<template>
  <div class="home">
    <h3>欢迎来到语言发展网</h3>
    <footer>
      <a href="http://beian.miit.gov.cn/" target="_blank">
        苏ICP备2024092162号-1
      </a>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>
<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: url(../assets/bg.png) no-repeat center center / cover;
  color: white;
  font-size: 24px;
  h3 {
    margin-top: 20vh;
  }
  footer {
    position: fixed;
    bottom: 0;
    a {
      line-height: 20px;
      font-size: 12px;
      color: gray;
    }
  }
}
</style>
