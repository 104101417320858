<template>
  <div class="student">
    <component v-if="studentInfo" :is="PDF" :studentInfo="studentInfo" />
    <Login v-else @submit="submit" />
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import Month24 from "@/components/pdfTempaltes/Month24.vue";
import Years4 from "@/components/pdfTempaltes/Years4.vue";
import BigClass from "@/components/pdfTempaltes/BigClass.vue";
import Grade1 from "@/components/pdfTempaltes/Grade1.vue";
import Grade2 from "@/components/pdfTempaltes/Grade2.vue";
import { getStudentInfoByName, checkFile } from "@/api";

export default {
  name: "Student",
  components: { Login, Month24, Years4, BigClass, Grade1, Grade2 },
  data() {
    return {
      studentInfo: window.studentInfo || null, // 自动化时注入的学生数据
      dev: this.$route.query.dev || false, // 是否是本地预览
    };
  },
  computed: {
    PDF() {
      const pdfTempate = this?.studentInfo?.pdfTempate;
      const view = {
        月_24_42: Month24,
        四岁以下: Years4,
        大班: BigClass,
        一年级: Grade1,
        二至六年级: Grade2,
      }[pdfTempate];
      return view || Years4;
    },
  },
  beforeCreate() {
    return;
    // 模拟数据
    window.studentInfo = {
      sourceInfo: {
        id: 8145,
        index: 2,
        name: "黄骏淇",
        gender: "男",
        birth: "2017-05-28",
        classroom: "雨花区长塘里思远小学-一年级-2303",
        teacher: "黎兰远",
        relation: "姓名:张妙|关系 （如父子）:母子",
        school50: "是",
        home50: "是",
        agree: "是",
        forverillness: "否",
        chinesemain: "是",
        wordmore: "是",
        degree: "大学/专科",
        mostlanguage: "普通话",
        day3more: "是",
        remark: "无",
        starttime: "2024-01-24 08:51:34",
        endtime: "2024-01-24 09:01:49",
        howlong: "10分15秒",
        address: "湖南省",
        city: "长沙市",
        ip: "58.20.77.138",
        browser: "Unknown Browser",
        os: "iPhone iOS 15.4.1",
        Q1: 1,
        Q2: 0,
        Q3: 0,
        Q4: 0,
        Q5: 0,
        Q6: 1,
        Q7: 0,
        Q8: 0,
        Q9: 1,
        Q10: 1,
        Q11: 0,
        Q12: 0,
        Q13: 0,
        Q14: 0,
        Q15: 1,
        Q16: 0,
        Q17: 0,
        Q18: 0,
        Q19: 1,
        Q20: 0,
        Q21: 1,
        Q22: 0,
        Q23: 0,
        Q24: 0,
        Q25: 2,
        Q26: 0,
        Q27: 0,
        Q28: 1,
        Q29: 0,
        Q30: 0,
        Q31: 0,
        Q32: 0,
        Q33: 1,
        Q34: 0,
        Q35: 0,
        Q36: 0,
        Q37: 0,
        Q38: 0,
        Q39: 0,
        Q40: 1,
        Q41: 0,
        Q42: 0,
        Q43: 0,
        Q44: 0,
        Q45: 1,
        Q46: 1,
        Q47: 0,
        Q48: 1,
        Q49: 0,
        Q50: 1,
        Q51: 0,
        Q52: 3,
        Q53: 3,
        Q54: 1,
        Q55: 1,
        Q56: 3,
        Q57: 1,
        Q58: 3,
        Q59: 3,
        Q60: 2,
        Q61: 1,
        Q62: 1,
        Q63: 2,
        Q64: 1,
        Q65: 1,
        Q66: 3,
        Q67: 1,
        Q68: 2,
        Q69: 2,
        Q70: 2,
        Q71: 2,
        createdAt: "2024-02-02T15:43:15.000Z",
        updatedAt: "2024-02-02T15:43:15.000Z",
      },
      ageYMD: {
        Y: 6,
        M: 7,
        D: 27,
      },
      ageMonth: 79.96853462709285,
      scores_1_71: {
        score: [0, 2, 6, 9, 4, 3, 7, 2, 2, 3],
        score_letter: {
          A: 0,
          B: 2,
          C: 6,
          D: 9,
          E: 4,
          F: 3,
          G: 7,
          H: 2,
          I: 2,
          J: 3,
        },
        total: 38,
      },
      tableScore: {
        table_score: [11, 10, 9, 6, 11, 10, 9, 12, 12, 14],
        table_score_letter: {
          A: 11,
          B: 10,
          C: 9,
          D: 6,
          E: 11,
          F: 10,
          G: 9,
          H: 12,
          I: 12,
          J: 14,
        },
        table_score_letter_precent: {
          A: 61,
          B: 48,
          C: 36,
          D: 8,
          E: 61,
          F: 48,
          G: 36,
          H: 74,
          I: 74,
          J: 91,
        },
        table_score_sum: 104,
        precent: [61, 48, 36, 8, 61, 48, 36, 74, 74, 91],
      },
      gccInfo: [78, 97, 40],
      sidiInfo: {
        tableScore: {
          A: 11,
          B: 10,
          C: 9,
          D: 6,
          E: 11,
          F: 10,
          G: 9,
          H: 12,
          I: 12,
          J: 14,
        },
        EHIJ: 49,
        ABCD: 36,
        SIDI: 13,
      },
      gradeName: "一年级",
      pdfTempate: "一年级",
      schoolName: "雨花区长塘里思远小学",
    };
  },
  mounted() {},
  methods: {
    async submit(values) {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      const { name, birth } = values;
      const res = await getStudentInfoByName(name, birth);

      if (res.code === 0) {
        const studentInfo = res.data;
        const { classroom, name, birth } = studentInfo.sourceInfo;
        // 本地预览
        if (this.dev) {
          this.studentInfo = studentInfo;
        } else {
          // 用户预览
          const link = `${classroom}/${classroom}-${birth}-${name}`;
          const exist = await checkFile(`${link}.pdf`);
          if (exist) {
            this.$router.push({
              name: "pdf",
              query: { link },
            });
          } else {
            this.$notify({
              type: "warning",
              message: "存在异常数据，请联系管理员",
            });
          }
        }
      } else {
        this.studentInfo = null;
        this.$notify({ type: "warning", message: res.message });
      }
      toast.clear();
    },
  },
};
</script>

<style lang="less" scoped>
.student {
}
</style>