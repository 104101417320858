

<template>
  <div class="pdf">
    <van-button type="primary" block :url="href">预览</van-button>
    <van-button block @click="down">下载</van-button>
  </div>
</template>

<script>
export default {
  name: "Pdf",
  computed: {
    link() {
      return this.$route.query.link;
    },
    filename() {
      return this.link.split("/")[1];
    },
    href() {
      return process.env.VUE_APP_API_BASE_URL + "/pdfs/" + this.link + ".pdf";
    },
  },
  methods: {
    down() {
      function downloadPdf(pdfDataUri, filename) {
        // 创建隐藏的可下载链接
        var link = document.createElement("a");
        link.href = pdfDataUri;
        link.download = filename;
        link.style.display = "none";

        // 触发点击事件以下载文件
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // 假设fetch或XHR请求获取到了PDF数据
      fetch(this.href)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建Blob URL
          const blobUrl = window.URL.createObjectURL(blob);
          // 调用下载函数
          downloadPdf(blobUrl, this.filename);
        });
    },
  },
};
</script>

<style scoped lang="less">
.pdf {
  padding: 16px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .van-button {
    margin-bottom: 20px;
  }
}
</style>